import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    notification: {},
    notificationlength: '',
  },
  getters: {
    getNotification: function(state){
      return state.notification
    },
  },
  mutations: {
    updateItem(state, payload){
      state.notification = payload
    },
    updateLength(state, payload){
      state.notificationlength = payload
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notification/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notification/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/notification/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notification/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notification/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
